import React, { useEffect, useState } from 'react';
import '../styles/Sidebar.css';
import { useSession } from '../SessionContext';

// const IP = "http://167.99.59.72:8000"; 
// const IP = "http://localhost:8000";
const IP = "https://reportes.aux-rolplay.xyz";

const Sidebar = ({ setView, activeView }) => {
  const { id_user, key } = useSession(); // Obtén id_user y key del contexto de sesión
  const [isAdmin, setIsAdmin] = useState(false); // Estado para verificar si es administrador

  const handleViewChange = (view) => {
    setView(view);
  };

  useEffect(() => {
    if (id_user && key) {
      fetch(`${IP}/check-user-authorization/?user_id=${id_user}&key=${key}`)
        .then(response => response.json())
        .then(data => {
          if (data.result === 1) {
            setIsAdmin(true); // Cambia el estado si es administrador
          }
        })
        .catch(error => console.error('Error checking authorization:', error));
    }
  }, [id_user, key]);

  return (
    <div className="sidebar">
      <div className="logo">
        <img src="/logo.svg" alt="Logo" draggable="false" />
      </div>

      <h2 className="section-title">Menu</h2>
      <ul className="menu">
        <li
          onClick={() => handleViewChange('TaskSummary')}
          className={`menu-item ${activeView === 'TaskSummary' ? 'active' : ''}`}
        >
          <i className="fas fa-bullseye"></i> Resumen
          {activeView === 'TaskSummary' && <span className="active-indicator"></span>}
        </li>
        <li
          onClick={() => handleViewChange('Rolplay')}
          className={`menu-item ${activeView === 'Rolplay' ? 'active' : ''}`}
        >
          <i className="fas fa-brain"></i> Rolplay
          {activeView === 'Rolplay' && <span className="active-indicator"></span>}
        </li>
        <li
          onClick={() => handleViewChange('Simulator')}
          className={`menu-item ${activeView === 'Simulator' ? 'active' : ''}`}
        >
          <i className="fas fa-microphone"></i> Simulador
          {activeView === 'Simulator' && <span className="active-indicator"></span>}
        </li>
        <li
          onClick={() => handleViewChange('access')}
          className={`menu-item ${activeView === 'access' ? 'active' : ''}`}
        >
          <i className="fas fa-key"></i> Acceso
          {activeView === 'access' && <span className="active-indicator"></span>}
        </li>
      </ul>

      {isAdmin && ( // Renderiza la sección "Administración" solo si el usuario es administrador
        <>
          <h2 className="section-title">Administración</h2>
          <ul className="menu">
            <li
              onClick={() => handleViewChange('General')}
              className={`menu-item ${activeView === 'General' ? 'active' : ''}`}
            >
              <i className="fas fa-cogs"></i> General
              {activeView === 'General' && <span className="active-indicator"></span>}
            </li>
            <li
              onClick={() => handleViewChange('Aprovisionamiento')}
              className={`menu-item ${activeView === 'Aprovisionamiento' ? 'active' : ''}`}
            >
              <i className="fas fa-toolbox"></i> Aprovisionamiento
              {activeView === 'Aprovisionamiento' && <span className="active-indicator"></span>}
            </li>
          </ul>
        </>
      )}

      <h2 className="section-title help-title">Ayuda</h2>
      <ul className="menu">
        <li
          onClick={() => handleViewChange('message')}
          className={`menu-item ${activeView === 'message' ? 'active' : ''}`}
        >
          <i className="fas fa-envelope"></i> Mensaje
          {activeView === 'message' && <span className="active-indicator"></span>}
        </li>
      </ul>

      <div className="footer">
        <p>Software manager for Administrators. Powered by <strong>Rolplay</strong></p>
      </div>
    </div>
  );
};

export default Sidebar;