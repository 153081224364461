import React, { useState, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../styles/Aprobaciones.css";
import Loader from "../components/Loader";
import { useSession } from "../SessionContext";

//const IP = "http://localhost:8000";
const IP = "https://reportes.aux-rolplay.xyz";

const PendingAcceptances = () => {
  const { id_user, key } = useSession();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedJSON, setSelectedJSON] = useState(null); // JSON seleccionado
  const [inspectorLoading, setInspectorLoading] = useState(false); // Estado de carga para el inspector
  const [modalVisible, setModalVisible] = useState(false); // Controla la visibilidad del modal
  const [isAccepting, setIsAccepting] = useState(false); // Estado para el botón "Aceptar"
  const [isDeleting, setIsDeleting] = useState(false); // Estado para el botón "Eliminar"
  const [notification, setNotification] = useState(null); // Para manejar mensajes de notificación

  useEffect(() => {
    const fetchPendingAcceptances = async () => {
      if (!id_user || !key) {
        setError("ID de usuario o clave no disponibles.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${IP}/pending-acceptances/?user_id=${id_user}&key=${key}`
        );
        const data = await response.json();

        if (data.status === "success") {
          const formattedData = data.data.map((item) => ({
            upserts_id: item.upserts_id,
            user_requesting_id: item.user_requesting_id,
            dateRequest_Upserts: new Date(item.dateRequest_Upserts).toLocaleString(),
          }));
          setRowData(formattedData);
          setError("");
        } else {
          setError("No se pudo obtener la información de los registros pendientes.");
        }
      } catch (err) {
        console.error("Error al obtener los registros pendientes:", err);
        setError("Error al conectarse con el servidor.");
        showNotification("Error al conectarse con el servidor.", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchPendingAcceptances();
  }, [id_user, key]);

  const showNotification = (message, type = "error") => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000); // Ocultar la notificación después de 3 segundos
  };

  const parseJSONSafely = (jsonString) => {
    try {
      let parsed = jsonString;
  
      parsed = parsed.trim();
      parsed = parsed.replace(/'/g, '"'); // Reemplazar comillas simples por dobles
      parsed = parsed.replace(/(\r\n|\n|\r)/gm, ""); // Eliminar saltos de línea y retornos de carro
      parsed = parsed.replace(/\\r/g, ""); // Eliminar cualquier \r dentro del contenido
  
      while (typeof parsed === "string") {
        parsed = JSON.parse(parsed);
      }
  
      // Si es un array, limpiar claves únicamente cuando haya \r
      if (Array.isArray(parsed)) {
        parsed = parsed.map((obj) => {
          if (typeof obj === "object" && obj !== null) {
            const cleanedObj = {};
            Object.keys(obj).forEach((key) => {
              const cleanKey = key.replace(/\r/g, ""); // Limpia las claves con \r
              cleanedObj[cleanKey] = obj[key];
            });
            return cleanedObj;
          }
          return obj;
        });
      }
  
      return parsed;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      showNotification("Error parsing JSON.", "error");
      return null;
    }
  };

  const handleInspectJSON = async (upserts_id) => {
    console.log("Botón 'Inspeccionar' presionado, upserts_id recibido:", upserts_id);
    setInspectorLoading(true);
    try {
      const response = await fetch(
        `${IP}/view-json/?upsert_id=${upserts_id}&user_id=${id_user}&key=${key}`
      );
      const data = await response.json();

      if (data.status === "success") {
        const parsedJSON = parseJSONSafely(data.json);
        console.log("JSON parseado correctamente:", parsedJSON);
        if (parsedJSON) {
          setSelectedJSON({ data: Array.isArray(parsedJSON) ? parsedJSON : [parsedJSON], upserts_id });
          setModalVisible(true);
        } else {
          setSelectedJSON({ data: [{ error: "El JSON no es válido." }], upserts_id });
        }
      } else {
        console.error("Error al obtener el JSON:", data.message);
        setSelectedJSON({ data: [{ error: "No se pudo obtener el JSON." }], upserts_id });
        showNotification("No se pudo obtener el JSON.", "error");
      }
    } catch (error) {
      console.error("Error fetching JSON:", error);
      setSelectedJSON({ data: [{ error: "Error al conectarse con el servidor." }], upserts_id });
      showNotification("Error al conectarse con el servidor.", "error");
    } finally {
      setInspectorLoading(false);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedJSON(null);
  };

  const handleAccept = async () => {
    console.log("Acción: Aceptar e insertar JSON en la base de datos");
  
    if (!selectedJSON || !selectedJSON.upserts_id) {
      console.error("No hay JSON seleccionado o falta el upserts_id.");
      showNotification("No hay JSON seleccionado o falta el upserts_id.", "error");
      return;
    }
  
    setIsAccepting(true);
  
    try {
      // Paso 1: Llamar al endpoint `view-json` para obtener el JSON correcto
      const viewResponse = await fetch(
        `${IP}/view-json/?upsert_id=${selectedJSON.upserts_id}&user_id=${id_user}&key=${key}`
      );
      const viewData = await viewResponse.json();
  
      if (viewData.status !== "success") {
        console.error("Error al obtener el JSON desde el servidor:", viewData.message);
        showNotification("Error al obtener el JSON desde el servidor.", "error");
        return;
      }
  
      const jsonStr = JSON.stringify(viewData.json); // Asegúrate de que es un array o formato esperado
  
      // Paso 2: Insertar los datos en la base de datos
      const insertResponse = await fetch(
        `${IP}/insert-bancoppel/?user_id=${id_user}&key=${key}&json_str=${encodeURIComponent(jsonStr)}`,
        {
          method: "PUT",
        }
      );
  
      const insertData = await insertResponse.json();
  
      if (insertData.status !== "success") {
        console.error("Error al insertar los datos en la base de datos:", insertData.message);
        showNotification("Error al insertar datos en la base de datos.", "error");
        return;
      }
  
      console.log("Datos insertados exitosamente en la base de datos.");
  
      // Paso 3: Actualizar el estado de la solicitud a 'aceptado'
      const updateResponse = await fetch(
        `${IP}/update-upsert-status/?upsert_id=${selectedJSON.upserts_id}&status=1&user_id=${id_user}&key=${key}`,
        {
          method: "PUT",
        }
      );
  
      const updateData = await updateResponse.json();
  
      if (updateData.status === "success") {
        console.log("Estado de la solicitud actualizado exitosamente.");
        closeModal();
        setRowData((prevData) =>
          prevData.filter((row) => row.upserts_id !== selectedJSON.upserts_id)
        );
      } else {
        console.error("Error al actualizar el estado de la solicitud:", updateData.message);
        showNotification("Error al actualizar el estado de la solicitud.", "error");
      }
    } catch (error) {
      console.error("Error durante el proceso de aceptación:", error);
      showNotification("Error durante el proceso de aceptación.", "error");
    } finally {
      setIsAccepting(false);
    }
  };

  const handleDelete = async () => {
    console.log("Acción: Eliminar JSON");

    if (!selectedJSON || !selectedJSON.upserts_id) {
      console.error("No hay JSON seleccionado o falta el upserts_id.");
      showNotification("No hay JSON seleccionado o falta el upserts_id.", "error");
      return;
    }

    setIsDeleting(true);

    try {
      const response = await fetch(
        `${IP}/update-upsert-status/?upsert_id=${selectedJSON.upserts_id}&status=2&user_id=${id_user}&key=${key}`,
        {
          method: "PUT",
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        console.log("Registro eliminado exitosamente:", data.message);
        closeModal();
        setRowData((prevData) =>
          prevData.filter((row) => row.upserts_id !== selectedJSON.upserts_id)
        );
      } else {
        console.error("Error al eliminar el registro:", data.message);
      }
    } catch (error) {
      console.error("Error al conectar con el servidor:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const columnDefs = useMemo(() => [
    {
      headerName: "ID",
      field: "upserts_id",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "ID Usuario Solicitante",
      field: "user_requesting_id",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Fecha de Solicitud",
      field: "dateRequest_Upserts",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Vista previa",
      field: "upserts_id",
      cellRenderer: (params) => (
        <button
          style={{
            backgroundColor: "#1b2a49",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleInspectJSON(params.value)}
        >
          <i className="fas fa-eye"></i> Inspeccionar
        </button>
      ),
      resizable: false,
      sortable: false,
      filter: false,
      width: 200,
      cellStyle: { textAlign: "center" },
    },
  ], [handleInspectJSON]);

  const modalColumnDefs = useMemo(() => [
    { headerName: "ID_EMPLEADO (numero o email)", field: "ID_EMPLEADO (numero o email)", flex: 1 },
    { headerName: "Admin", field: "admin", flex: 1 },
    { headerName: "Sucursal", field: "sucursal (para asignar usuarios al mismo grupo)", flex: 1 },
    { headerName: "Puesto o Línea", field: "puesto o linea", flex: 1 },
    { headerName: "Email", field: "email (Solo si el usuario recibira credenciales a su correo)", flex: 1 },
    { headerName: "Nombre", field: "Nombre (opcional)", flex: 1 },
    { headerName: "Campo Info 1", field: "campo_info1", flex: 1 },
    { headerName: "Campo Info 2", field: "campo_info2", flex: 1 },
    { headerName: "Campo Info 3", field: "campo_info3", flex: 1 },
  ], []);

  return (
    <div className="pending-acceptances-table-container">
      {loading ? (
        <Loader />
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{
            height: "600px",
            width: "100%",
          }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={20}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
            }}
          />
        </div>
      )}

      {/* Contenedor de notificación */}
      {notification && (
        <div className={`notificationapro ${notification.type}`}>
          {notification.message}
        </div>
      )}

      {inspectorLoading && <Loader />}

      {modalVisible && (
        <div className="json-inspector-overlay">
          <div className="json-inspector-container">
            <div
              className="ag-theme-alpine"
              style={{
                height: "400px",
                width: "100%",
              }}
            >
              <AgGridReact
                rowData={selectedJSON?.data || []}
                columnDefs={modalColumnDefs}
                pagination={true}
                paginationPageSize={5}
                defaultColDef={{
                  sortable: true,
                  filter: true,
                  resizable: true,
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "10px" }}>
              {isAccepting ? (
                <Loader />
              ) : (
                <button
                  onClick={handleAccept}
                  style={{
                    backgroundColor: "#28a745",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  <i className="fas fa-check"></i> Aceptar
                </button>
              )}
              {isDeleting ? (
                <Loader />
              ) : (
                <button
                  onClick={handleDelete}
                  style={{
                    backgroundColor: "#dc3545",
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  <i className="fas fa-trash"></i> Eliminar
                </button>
              )}
              <button
                onClick={closeModal}
                style={{
                  backgroundColor: "#f2f2f2",
                  color: "#333",
                  border: "none",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
              >
                <i className="fas fa-times"></i> Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingAcceptances;