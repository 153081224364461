import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/Access.css';
import Loader from './Loader';
import { useSession } from '../SessionContext'; // Importa el contexto de sesión

// const IP = "http://167.99.59.72:8000"; 
// const IP = "http://localhost:8000";
const IP = "https://reportes.aux-rolplay.xyz";

const LogTable = () => {
  const { id_user, key } = useSession(); // Usa el contexto de sesión para obtener id_user y key
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const columnDefs = [
    { headerName: 'Nombre', field: 'log_user_full_name', sortable: true, resizable: true, filter: true, flex: 1 },
    { headerName: 'Usuario', field: 'log_user', sortable: true, resizable: true, filter: true, flex: 1 },
    { headerName: 'Puesto', field: 'log_type_user', sortable: true, resizable: true, filter: true, flex: 1 },
    { headerName: 'Cuenta', field: 'log_user_code', sortable: true, resizable: true, filter: true, flex: 1 },
    { headerName: 'Sucursal', field: 'log_user_branch', sortable: true, resizable: true, filter: true, flex: 1 },
    { headerName: 'Fecha', field: 'log_datetime', sortable: true, resizable: false, filter: true, flex: 1 }
  ];

  useEffect(() => {
    if (id_user && key) {
      fetch(`${IP}/exec-query-access/?user_id=${id_user}&key=${key}`)
        .then(response => response.json())
        .then(data => {
          setRowData(data.result);
          setIsLoading(false); // Desactiva el loader al finalizar la carga de datos
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    }
  }, [id_user, key]);

  const onExportClick = () => {
    if (!gridApi) return;
  
    // Obtener las filas filtradas
    const filteredRows = [];
    gridApi.forEachNodeAfterFilterAndSort((node) => {
      filteredRows.push(node.data);
    });
  
    // Mapear los datos filtrados para exportar en formato CSV
    const csvData = filteredRows.map(row => ({
      'Nombre': row.log_user_full_name,
      'Usuario': row.log_user,
      'Puesto': row.log_type_user,
      'Cuenta': row.log_user_code,
      'Sucursal': row.log_user_code,
      'Fecha': row.log_datetime
    }));
  
    const csvContent = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filtered_log_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onFilterTextChange = (e) => {
    setSearchText(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  return (
    <div className="log-table-container">
      {isLoading && <Loader />}

      {!isLoading && (
        <>
          <div className="header-container">
            <div className="tabs">
              <button className="tab-button active">Accesos</button>
            </div>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Buscar..."
                value={searchText}
                onChange={onFilterTextChange}
              />
            </div>
          </div>
          <div className="log-table ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={20}
              onGridReady={onGridReady}
              defaultColDef={{
                sortable: true,
                resizable: true,
                flex: 1
              }}
            />
          </div>
          <div className="download-btn-container">
            <button className="download-btn" onClick={onExportClick}>
              <i className="fas fa-download"></i> Descargas
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LogTable;