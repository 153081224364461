import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Rolplay from './components/Rolplay';
import Simulator from './components/Simulator';
import TaskSummary from './components/TaskSummary';
import MessageForm from './components/MessageForm';
import LogTable from './components/Access';
import { useSession } from './SessionContext';

function App() {
  const [view, setView] = useState('TaskSummary'); // Estado de la vista activa
  const { updateSession } = useSession();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id_user = queryParams.get('id_user');
    const key = queryParams.get('key');

    if (id_user && key) {
      updateSession(id_user, key);
    }
  }, [location.search, updateSession]);

  return (
    <div className="app-container">
      <Sidebar setView={setView} activeView={view} /> {/* Pasamos activeView */}
      <div className="main-content">
        {view === 'TaskSummary' && <TaskSummary setView={setView} />}
        {view === 'Rolplay' && <Rolplay />}
        {view === 'Simulator' && <Simulator />}
        {view === 'access' && <LogTable />}
        {view === 'message' && <MessageForm />}
      </div>
    </div>
  );
}

export default App;