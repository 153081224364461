import React, { useState } from 'react';
import '../styles/MessageForm.css';

const MessageForm = () => {
  const [email, setEmail] = useState('');
  const [user, setUser] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const supportEmail = "soporte-rolplay@audioweb.com.mx"; // Dirección de correo de soporte
    const subject = `Support Request from ${user}`;
    const body = `User: ${user} Email: ${email} Message: ${message}`;
    
    // Construimos el enlace mailto con los datos del formulario
    window.location.href = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <div className="message-form">
      <form onSubmit={handleSubmit}>
        <h2>Support Message</h2>
        <label>Email:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

        <label>Username:</label>
        <input type="text" value={user} onChange={(e) => setUser(e.target.value)} required />

        <label>Message:</label>
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} required />

        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default MessageForm;