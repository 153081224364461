import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/Rolplay.css';
import Loader from './Loader';
import { useSession } from '../SessionContext'; // Importa el contexto de sesión

//const IP = "http://localhost:8000";
const IP = "https://reportes.aux-rolplay.xyz";

const FollowUp = () => {
  const { id_user, key } = useSession(); // Usa el contexto de sesión para obtener id_user y key
  const [realizadasData, setRealizadasData] = useState([]);
  const [noRealizadasData, setNoRealizadasData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState('Realizadas');
  const [isLoading, setIsLoading] = useState(true);

  const realizadasColumns = [
    { headerName: 'Gerente', field: 'admin_name', sortable: true, resizable: true, filter: true },
    { headerName: 'Usuario', field: 'reg_user_access', sortable: true, resizable: true, filter: true },
    { headerName: 'Nombre Usuario', field: 'reg_user_name', sortable: true, resizable: true, filter: true },
    { headerName: 'Actividad', field: 'reg_activity_name', sortable: true, resizable: true, filter: true },
    { headerName: 'Ejercicio', field: 'reg_exercise_name', sortable: true, resizable: true, filter: true },
    { headerName: 'Rúbrica', field: 'reg_robin_rubric_score', sortable: true, resizable: true, filter: true },
    { headerName: 'Facial (Calificación)', field: 'reg_robin_facial_score', sortable: true, resizable: true, filter: true },
    { headerName: 'Voz (Calificación)', field: 'reg_robin_voice_score', sortable: true, resizable: true, filter: true },
    { headerName: 'Palabras / Min (Calificación)', field: 'reg_robin_ppm_score', sortable: true, resizable: true, filter: true },
    { headerName: 'Calificación', field: 'reg_robin_rubric_total', sortable: true, resizable: true, filter: true },
    { headerName: 'Fecha Grabación', field: 'reg_datetime', sortable: true, resizable: false, filter: true }
  ];

  const noRealizadasColumns = [
    { headerName: 'Usuario', field: 'assign_user_name', sortable: true, resizable: true, filter: true },
    { headerName: 'Sucursal', field: 'user_branch', sortable: true, resizable: true, filter: true },
    { headerName: 'Puesto', field: 'user_profile', sortable: true, resizable: true, filter: true },
    { headerName: 'Titulo', field: 'assign_title', sortable: true, resizable: false, filter: true }
  ];

  useEffect(() => {
    if (id_user && key) {
      fetch(`${IP}/exec-query-rolplay/?user_id=${id_user}&key=${key}`)
        .then(response => response.json())
        .then(data => {
          setRealizadasData(data.result.Realizadas);
          setNoRealizadasData(data.result["No realizadas"]);
          setRowData(data.result.Realizadas);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    }
  }, [id_user, key]);

  const onExportClick = () => {
    if (!gridApi) return;
  
    // Obtener las filas filtradas
    const filteredRows = [];
    gridApi.forEachNodeAfterFilterAndSort((node) => {
      filteredRows.push(node.data);
    });
  
    // Seleccionar los datos a exportar según la pestaña activa
    const dataToExport = activeTab === 'Realizadas' ? filteredRows : filteredRows;
  
    const csvData = dataToExport.map(row => {
      if (activeTab === 'Realizadas') {
        return {
          'Gerente': row.admin_name,
          'Usuario': row.reg_user_access,
          'Nombre Usuario': row.reg_user_name,
          'Actividad': row.reg_activity_name,
          'Ejercicio': row.reg_exercise_name,
          'Rubrica': row.reg_robin_rubric_score,
          'Facial (Calificación)': row.reg_robin_facial_score,
          'Voz (Calificación)': row.reg_robin_voice_score,
          'Palabras / Min (Calificación)': row.reg_robin_ppm_score,
          'Calificación': row.reg_robin_rubric_total,
          'Fecha Grabación': row.reg_datetime
        };
      } else {
        return {
          'Usuario': row.assign_user_name,
          'Sucursal': row.user_branch,
          'Puesto': row.user_profile,
          'Title': row.assign_title
        };
      }
    });
  
    const csvContent = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${activeTab}_filtered_data.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onFilterTextChange = (e) => {
    setSearchText(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const switchTab = (tab) => {
    setActiveTab(tab);
    setRowData(tab === 'Realizadas' ? realizadasData : noRealizadasData);
  };

  return (
    <div className="followup-container">
      {isLoading && <Loader />}

      {!isLoading && (
        <>
          <div className="header-container">
            <div className="tabs">
              <button
                className={`tab-button ${activeTab === 'Realizadas' ? 'active' : ''}`}
                onClick={() => switchTab('Realizadas')}
              >
                Tareas Completadas
              </button>
              <button
                className={`tab-button ${activeTab === 'No realizadas' ? 'active' : ''}`}
                onClick={() => switchTab('No realizadas')}
              >
                Tareas Pendientes
              </button>
            </div>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Buscar..."
                value={searchText}
                onChange={onFilterTextChange}
              />
            </div>
          </div>
          <div className="followup-table ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={activeTab === 'Realizadas' ? realizadasColumns : noRealizadasColumns}
              pagination={true}
              paginationPageSize={20}
              onGridReady={onGridReady}
              defaultColDef={{
                sortable: true,
                resizable: true,
                flex: 1
              }}
            />
          </div>
          <div className="download-btn-container">
            <button className="download-btn" onClick={onExportClick}>
              <i className="fas fa-download"></i> Descargar
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FollowUp;