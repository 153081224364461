import React, { useEffect, useState } from 'react';
import '../styles/Sidebar.css';
import { useSession } from '../SessionContext';

//const IP = "http://localhost:8000"; // Cambiar según sea necesario
const IP = "https://reportes.aux-rolplay.xyz";

const Sidebar = ({ setView, activeView }) => {
  const { id_user, key } = useSession(); // Obtén id_user y key del contexto de sesión
  const [isAdminAprovisionamiento, setIsAdminAprovisionamiento] = useState(false); // Estado para "Aprovisionamiento"
  const [isAdminAprovaciones, setIsAdminAprovaciones] = useState(false); // Estado para "Aprovaciones"
  const [menuPermissions, setMenuPermissions] = useState({
    mod_resumen: false,
    mod_rolplay: false,
    mod_simulador: false,
    mod_access: false,
    mod_simulador_feedback: false,
  }); // Estado para las opciones del menú

  const handleViewChange = (view) => {
    setView(view);
  };

  useEffect(() => {
    if (id_user && key) {
      // Verificar autorización para Aprovisionamiento
      fetch(`${IP}/check-user-authorization_aprovisionamiento/?user_id=${id_user}&key=${key}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.result === 1) {
            setIsAdminAprovisionamiento(true);
          }
        })
        .catch((error) =>
          console.error('Error checking authorization for Aprovisionamiento:', error)
        );

      // Verificar autorización para Aprovaciones
      fetch(`${IP}/check-user-authorization_aprovacion/?user_id=${id_user}&key=${key}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.result === 1) {
            setIsAdminAprovaciones(true);
          }
        })
        .catch((error) =>
          console.error('Error checking authorization for Aprovaciones:', error)
        );

      // Verificar autorizaciones para las opciones del menú
      fetch(`${IP}/check-user-authorization_menu/?user_id=${id_user}&key=${key}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "authorized") {
            setMenuPermissions({
              mod_resumen: data.result.mod_resumen === 1,
              mod_rolplay: data.result.mod_rolplay === 1,
              mod_simulador: data.result.mod_simulador === 1,
              mod_access: data.result.mod_access === 1,
              mod_simulador_feedback: data.result.mod_simulador_feedback === 1,
            });
          }
        })
        .catch((error) =>
          console.error('Error checking authorization for Menu options:', error)
        );
    }
  }, [id_user, key]);

  return (
    <div className="sidebar">
      <div className="logo">
        <img src="/logo.svg" alt="Logo" draggable="false" />
      </div>

      <h2 className="section-title">Menu</h2>
      <ul className="menu">
        {menuPermissions.mod_resumen && (
          <li
            onClick={() => handleViewChange('TaskSummary')}
            className={`menu-item ${activeView === 'TaskSummary' ? 'active' : ''}`}
          >
            <i className="fas fa-bullseye"></i> Resumen
            {activeView === 'TaskSummary' && <span className="active-indicator"></span>}
          </li>
        )}
        {menuPermissions.mod_rolplay && (
          <li
            onClick={() => handleViewChange('Rolplay')}
            className={`menu-item ${activeView === 'Rolplay' ? 'active' : ''}`}
          >
            <i className="fas fa-brain"></i> Rolplay
            {activeView === 'Rolplay' && <span className="active-indicator"></span>}
          </li>
        )}
        {/* Lógica dinámica para Simulador */}
          {menuPermissions.mod_simulador && (
            <li
              onClick={() =>
                handleViewChange(
                  menuPermissions.mod_simulador_feedback ? 'simuladorfeed' : 'Simulator'
                )
              }
              className={`menu-item ${
                activeView === 'Simulator' || activeView === 'simuladorfeed' ? 'active' : ''
              }`}
            >
              <i className="fas fa-microphone"></i> Simulador
              {(activeView === 'Simulator' || activeView === 'simuladorfeed') && (
                <span className="active-indicator"></span>
              )}
            </li>
          )}
        {menuPermissions.mod_access && (
          <li
            onClick={() => handleViewChange('access')}
            className={`menu-item ${activeView === 'access' ? 'active' : ''}`}
          >
            <i className="fas fa-key"></i> Acceso
            {activeView === 'access' && <span className="active-indicator"></span>}
          </li>
        )}
      </ul>

      {/* Sección de Administración */}
      {(isAdminAprovisionamiento || isAdminAprovaciones || menuPermissions) && (
        <>
          <h2 className="section-title">Administración</h2>
          <ul className="menu">
            {isAdminAprovisionamiento && (
              <li
                onClick={() => handleViewChange('aprovisionamiento')}
                className={`menu-item ${activeView === 'aprovisionamiento' ? 'active' : ''}`}
              >
                <i className="fas fa-toolbox"></i> Aprovisionamiento
                {activeView === 'aprovisionamiento' && <span className="active-indicator"></span>}
              </li>
            )}
            {isAdminAprovaciones && (
              <li
                onClick={() => handleViewChange('aprobaciones')}
                className={`menu-item ${activeView === 'aprobaciones' ? 'active' : ''}`}
              >
                <i className="fas fa-check-circle"></i> Aprobaciones
                {activeView === 'aprobaciones' && <span className="active-indicator"></span>}
              </li>
            )}
          </ul>
        </>
      )}

      <h2 className="section-title help-title">Ayuda</h2>
      <ul className="menu">
        <li
          onClick={() => handleViewChange('message')}
          className={`menu-item ${activeView === 'message' ? 'active' : ''}`}
        >
          <i className="fas fa-envelope"></i> Mensaje
          {activeView === 'message' && <span className="active-indicator"></span>}
        </li>
      </ul>

      <div className="footer">
        <p>
          Software manager for Administrators. Powered by <strong>Rolplay</strong>
        </p>
      </div>
    </div>
  );
};

export default Sidebar;