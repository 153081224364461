import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { AgGridReact } from 'ag-grid-react';
import Loader from './Loader'; // Asegúrate de importar tu componente Loader
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/Aprovisionamiento.css';
import { useSession } from '../SessionContext'; // Importa el contexto de sesión

//const IP = "http://localhost:8000";
const IP = "https://reportes.aux-rolplay.xyz";


const Aprovisionamiento = () => {
  const { id_user, key } = useSession();
  const [fileData, setFileData] = useState([]);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Estado para el loader

  const handleFileUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target.result;
      const rows = text.split('\n').map((row) => row.split(','));

      const headers = rows[0];
      const data = rows.slice(1).map((row) => {
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[index];
        });
        return rowData;
      });

      setFileData(data);
      setIsFileLoaded(true);
      setIsDragActive(false);
    };

    reader.readAsText(file);
  };

  const handleDragEnter = () => setIsDragActive(true);
  const handleDragLeave = () => setIsDragActive(false);

  const clearTable = () => {
    setFileData([]);
    setIsFileLoaded(false);
  };

  const downloadTemplate = () => {
    const templateContent = `ID_EMPLEADO (numero o email),admin,sucursal (para asignar usuarios al mismo grupo),puesto o linea,email (Solo si el usuario recibira credenciales a su correo),Nombre (opcional),campo_info1,campo_info2,campo_info3
123456iii,Juan Perez Lopez 200,12345,CAJERO MIXTO BANCO,a@a.com,Juan Perez Lopez,Dato Alfanumerico,Dato Alfanumerico,Dato Alfanumerico
1234e678,Juan Perez Lopez,12345,CAJERO MIXTO BANCO,aa.com,Juan Perez Lopez,Dato Alfanumerico,Dato Alfanumerico,Dato Alfanumerico`;

    const blob = new Blob([templateContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sendDataToEndpoint = async () => {
    setIsLoading(true); // Activa el loader
    const userId = id_user
    const apiKey = key;
    const requestData = { n_registExecute: fileData };
  
    try {
      const response = await fetch(
        `${IP}/process-administradores/?user_id=${userId}&key=${apiKey}&n_registExecute=${encodeURIComponent(
          JSON.stringify(requestData.n_registExecute)
        )}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        }
      );
  
      if (response.ok) {
        const result = await response.json();
  
        // Manejo del status success
        if (result.status === 'success') {
          if (result.dontsaved && result.dontsaved.length > 0) {
            // Warning si hay datos no guardados
            addNotification(
              'warning',
              'Algunos usuarios no pudieron enviarse',
              'Revisar los usuarios restantes en la tabla.'
            );
            setFileData(result.dontsaved); // Actualizamos la tabla con los usuarios no enviados
          } else {
            // Success si todos los datos se enviaron correctamente
            addNotification('success', 'Proceso completado', 'Todos los usuarios fueron enviados exitosamente.');
            clearTable(); // Limpiamos la tabla
          }
        } else if (result.status === 'error') {
          // Manejo del status error
          if (result.dontsaved && result.dontsaved.length > 0) {
            // Warning si hay datos no guardados
            addNotification(
              'warning',
              'Algunos usuarios no pudieron enviarse',
              'Revisar los usuarios restantes en la tabla.'
            );
            setFileData(result.dontsaved); // Actualizamos la tabla con los usuarios no enviados
          } else if (result.message) {
            // Error si no hay datos no guardados pero hay un mensaje de error
            const columnName = result.message.split(': ')[1]?.replace(/'/g, ''); // Extraer la columna del mensaje
            addNotification(
              'error',
              'Error al procesar datos',
              columnName
                ? `Hay un error con la columna "${columnName}". Verifica los datos ingresados.`
                : 'Hubo un error al procesar los datos. Por favor verifica tu información.'
            );
          } else {
            // Error genérico si no hay mensaje ni datos no guardados
            addNotification('error', 'Error al enviar', 'Hubo un problema desconocido al enviar los datos.');
          }
        }
      } else {
        // Error de comunicación con el servidor
        addNotification('error', 'Error en la solicitud', 'No se pudo conectar al servidor.');
      }
    } catch (error) {
      // Error de red u otro problema
      addNotification('error', 'Error en la solicitud', 'No se pudo conectar al servidor.');
    } finally {
      setIsLoading(false); // Desactiva el loader al finalizar
    }
  };

  const addNotification = (type, title, message) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, type, title, message }]);
    setTimeout(() => removeNotification(id), 5000); // Desaparece después de 5 segundos
  };

  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const columnDefs = [
    { headerName: 'ID_EMPLEADO (numero o email)', field: 'ID_EMPLEADO (numero o email)', sortable: true, resizable: true, filter: true, editable: true, flex: 1 },
    { headerName: 'Admin', field: 'admin', sortable: true, resizable: true, filter: true, editable: true, flex: 1 },
    { headerName: 'Sucursal', field: 'sucursal (para asignar usuarios al mismo grupo)', sortable: true, resizable: true, filter: true, editable: true, flex: 1 },
    { headerName: 'Puesto o Línea', field: 'puesto o linea', sortable: true, resizable: true, filter: true, editable: true, flex: 1 },
    { headerName: 'Email', field: 'email (Solo si el usuario recibira credenciales a su correo)', sortable: true, resizable: true, filter: true, editable: true, flex: 1 },
    { headerName: 'Nombre', field: 'Nombre (opcional)', sortable: true, resizable: true, filter: true, editable: true, flex: 1 },
    { headerName: 'Campo Info 1', field: 'campo_info1', sortable: true, resizable: true, filter: true, editable: true, flex: 1 },
    { headerName: 'Campo Info 2', field: 'campo_info2', sortable: true, resizable: true, filter: true, editable: true, flex: 1 },
    { headerName: 'Campo Info 3', field: 'campo_info3', sortable: true, resizable: true, filter: true, editable: true, flex: 0 },
  ];

  return (
    <div className="aprovisionamiento-container">
      {isLoading && <Loader />} {/* Muestra el loader mientras está cargando */}
      <div className="notifications-container">
        {notifications.map((notification) => (
          <div key={notification.id} className={`notification ${notification.type}`}>
            <h4>{notification.title}</h4>
            <p>{notification.message}</p>
          </div>
        ))}
      </div>
      <div className="header-actions">
        <button className="download-template-button" onClick={downloadTemplate}>
        <i class="fas fa-download"></i> Descargar template
        </button>
      </div>
      {!isFileLoaded && (
        <Dropzone
          onDrop={handleFileUpload}
          accept=".csv"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? 'drag-active' : ''}`}
            >
              <input {...getInputProps()} />
              <i class="fas fa-file"></i>
              <p className="file-upload-text">
              &nbsp; Cargar tu <span className="csv-highlight">CSV</span> con los administrados para enviarlos al proceso de aprobación
              </p>
            </div>
          )}
        </Dropzone>
      )}
      {isFileLoaded && (
        <div className="ag-theme-alpine grid-container" style={{ height: '600px', width: '100%' }}>
          <AgGridReact
            rowData={fileData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
            domLayout="autoHeight"
          />
        </div>
      )}
      <div className="button-container">
        <button className="clear-button" onClick={clearTable} disabled={!isFileLoaded}>
        <i class="fas fa-broom"></i> Limpiar tabla
        </button>
        <button className="submit-button" onClick={sendDataToEndpoint} disabled={!isFileLoaded}>
        <i class="fas fa-cloud-upload-alt"></i> Cargar
        </button>
      </div>
    </div>
  );
};

export default Aprovisionamiento;