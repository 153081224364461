// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #ffffff;
  color: #333;
  min-height: 100vh; /* Asegura que el cuerpo ocupe toda la altura de la ventana */
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 20px;
  font-weight: bold;
}

h2 {
  font-size: 14px;
  font-weight: 400;
}

p {
  font-size: 12px;
  line-height: 1.5;
}

.app-container {
  display: flex;
  flex: 1 1;
  width: 100%;
  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */
}

.main-content {
  flex: 1 1;
  padding: 20px;
  background-color: #f2f2f2;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,WAAW;EACX,iBAAiB,EAAE,6DAA6D;EAChF,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,WAAW;EACX,iBAAiB,EAAE,iEAAiE;AACtF;;AAEA;EACE,SAAO;EACP,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["/* Global styles */\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: 'Inter', sans-serif;\n  background-color: #ffffff;\n  color: #333;\n  min-height: 100vh; /* Asegura que el cuerpo ocupe toda la altura de la ventana */\n  display: flex;\n  flex-direction: column;\n}\n\nh1 {\n  font-size: 20px;\n  font-weight: bold;\n}\n\nh2 {\n  font-size: 14px;\n  font-weight: 400;\n}\n\np {\n  font-size: 12px;\n  line-height: 1.5;\n}\n\n.app-container {\n  display: flex;\n  flex: 1;\n  width: 100%;\n  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */\n}\n\n.main-content {\n  flex: 1;\n  padding: 20px;\n  background-color: #f2f2f2;\n  overflow-y: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
