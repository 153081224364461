import React, { createContext, useContext, useState, useEffect, useCallback } from "react";

// Creamos el contexto de sesión
const SessionContext = createContext();

// Hook para acceder al contexto de sesión
export const useSession = () => {
  return useContext(SessionContext);
};

// Proveedor de sesión
export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState({
    id_user: null,
    key: null,
  });

  // Función para actualizar la sesión con id_user y key (memorizada con useCallback)
  const updateSession = useCallback((id_user, key) => {
    setSession({ id_user, key });
  }, []); // Evita que React lo trate como una dependencia cambiante

  // Al cargar el componente, lee los valores de la URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id_user = params.get("id_user");
    const key = params.get("key");

    if (id_user && key) {
      updateSession(id_user, key);
      console.log("Session initialized with:", { id_user, key }); // Verificar que se capturan correctamente
    }
  }, [updateSession]); // Ahora el useEffect depende de la función memorizada

  return (
    <SessionContext.Provider value={{ ...session, updateSession }}>
      {children}
    </SessionContext.Provider>
  );
};