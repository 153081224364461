import React, { useEffect, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/TaskSummary.css';
import Loader from './Loader';
import { useSession } from '../SessionContext';

// const IP = "http://167.99.59.72:8000"; 
// const IP = "http://localhost:8000";
const IP = "https://reportes.aux-rolplay.xyz";

const TaskSummary = ({ setView }) => { // Recibe setView como prop
  const { id_user, key } = useSession();
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id_user && key) {
      const url = `${IP}/exec-query-summary/?user_id=${id_user}&key=${key}`;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const formattedData = Object.entries(data.result.task_summary).map(([name, activities]) => ({
            name,
            rolplayAssign: activities.rolplay.Assign,
            rolplayDone: activities.rolplay.Done,
            rolplayProgress: activities.rolplay.Assign > 0 ? (activities.rolplay.Done / activities.rolplay.Assign) * 100 : 0,
            simuladoresDone: activities.simuladores.Done
          }));
          setRowData(formattedData);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    }
  }, [id_user, key]);

  const columnDefs = useMemo(() => [
    { headerName: 'Nombre', field: 'name', sortable: true, filter: true },
    { 
      headerName: 'Rolplay Activities', 
      field: 'activities', 
      valueGetter: (params) => `${params.data.rolplayDone}/${params.data.rolplayAssign}`, 
      sortable: true 
    },
    {
      headerName: 'Rolplay Progress',
      field: 'rolplayProgress',
      cellRenderer: (params) => (
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={{ width: `${params.value}%` }}
          />
        </div>
      )
    },
    {
      headerName: 'Rolplay Details',
      field: 'details',
      cellRenderer: () => (
        <button
          className="details-button"
          onClick={() => setView('Rolplay')} // Cambia la vista a 'Rolplay' sin redirigir
        >
          Rolplay Details
        </button>
      )
    },
    { 
      headerName: 'Simuladores Done', 
      field: 'simuladoresDone', 
      sortable: true 
    },
    {
      headerName: 'Simuladores Details',
      field: 'simuladoresDetails',
      cellRenderer: () => (
        <button
          className="details-button"
          onClick={() => setView('Simulator')} // Cambia la vista a 'Simulator' sin redirigir
        >
          Simuladores Details
        </button>
      )
    }
  ], [setView]);

  return (
    <div className="task-summary-container ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
      {isLoading ? (
        <Loader />
      ) : (
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={20}
          defaultColDef={{
            flex: 1,
            resizable: true,
            sortable: true
          }}
        />
      )}
    </div>
  );
};

export default TaskSummary;