import React, { useEffect, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/Simulator.css';
import Loader from './Loader';
import { useSession } from '../SessionContext'; // Importa el contexto de sesión

//const IP = "http://localhost:8000";
const IP = "https://reportes.aux-rolplay.xyz";

const FollowUp = () => {
  const { id_user, key } = useSession(); // Usa el contexto de sesión para obtener id_user y key
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false); // Controla la visibilidad del modal
  const [modalUrl, setModalUrl] = useState(''); // URL para mostrar en el modal
  const [activeTab, setActiveTab] = useState('Simuladores'); // Estado para el botón "Simuladores"

  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false); // Controla visibilidad del modal
  const [feedbackContent, setFeedbackContent] = useState(''); // Contenido del comentario

  const columnDefs = useMemo(() => [
    { headerName: 'Gerente', field: 'sim_gerente', sortable: true, resizable: true, filter: true },
    { headerName: 'Nombre Usuario', field: 'sim_user', sortable: true, resizable: true, filter: true },
    { headerName: 'Usuario', field: 'sim_user_access', sortable: true, resizable: true, filter: true },
    { headerName: 'Sucursal', field: 'user_branch', sortable: true, resizable: true, filter: true },
    { headerName: 'Puesto', field: 'user_profile', sortable: true, resizable: true, filter: true },
    { headerName: 'Simulador', field: 'sim_title', sortable: true, resizable: true, filter: true },
    { headerName: 'Calificación', field: 'sim_score', sortable: true, resizable: true, filter: true },
    { headerName: 'Fecha', field: 'sim_datetime', sortable: true, resizable: true, filter: true },
    {
      headerName: 'Vista previa',
      field: 'sim_view_url',
      cellRenderer: (params) => (
        <button
          style={{
            backgroundColor: '#1b2a49',
            color: 'white',
            border: 'none',
            padding: '5px 10px',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          onClick={() => openModal(params.value)} // Abre el modal de vista previa
        >
        <i className="fas fa-eye"></i> Vista previa
        </button>
      ),
      resizable: false,
      sortable: false,
      filter: false,
      width: 100,
      cellStyle: { textAlign: 'center' },
    },
  ], []);

  useEffect(() => {
    if (id_user && key) {
      const url = `${IP}/exec-query-simulador/?user_id=${id_user}&key=${key}`;

      fetch(url)
        .then(response => response.json())
        .then(data => {
          setRowData(data.result);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    }
  }, [id_user, key]);

  const openModal = (url) => {
    setModalUrl(url); // Establece la URL en el estado
    setModalVisible(true); // Muestra el modal
  };

  const closeModal = () => {
    setModalVisible(false); // Cierra el modal
    setModalUrl(''); // Limpia la URL
  };

  const openFeedbackModal = (feedback) => {
    setFeedbackContent(feedback); // Establece el contenido del comentario
    setFeedbackModalVisible(true); // Muestra el modal
  };
  
  const closeFeedbackModal = () => {
    setFeedbackModalVisible(false); // Oculta el modal
    setFeedbackContent(''); // Limpia el contenido
  };

  const onExportClick = () => {
    if (!gridApi) return;
  
    // Obtener las filas filtradas
    const filteredRows = [];
    gridApi.forEachNodeAfterFilterAndSort((node) => {
      filteredRows.push(node.data);
    });
  
    // Mapear los datos filtrados para exportar en el formato de CSV
    const csvData = filteredRows.map(row => ({
      'Gerente': row.sim_gerente,
      'Nombre Usuario': row.sim_user,
      'Usuario': row.sim_user_access,
      'Sucursal': row.user_branch,
      'Puesto': row.user_profile,
      'Simulador': row.sim_title,
      'Calificación': row.sim_score,
      'Fecha': row.sim_datetime,
      'Vista previa': row.sim_view_url
    }));
  
    const csvContent = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'simulator_filtered_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onFilterTextChange = (e) => {
    setSearchText(e.target.value);
    gridApi.setQuickFilter(e.target.value);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const switchTab = (tab) => {
    setActiveTab(tab); // Cambia el estado al hacer clic en el botón "Simuladores"
  };

  return (
    <div className="followup-container">
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <div className="header-container">
            <div className="tabs">
              <button
                className={`tab-button ${activeTab === 'Simuladores' ? 'active' : ''}`}
                onClick={() => switchTab('Simuladores')}
              >
                Simuladores
              </button>
            </div>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Buscar..."
                value={searchText}
                onChange={onFilterTextChange}
              />
            </div>
          </div>
          <div className="followup-table ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={20}
              onGridReady={onGridReady}
              defaultColDef={{
                sortable: true,
                resizable: true,
                flex: 1
              }}
            />
          </div>
          <div className="download-btn-container">
            <button className="download-btn" onClick={onExportClick}>
              <i className="fas fa-download"></i> Descargar
            </button>
          </div>
        </>
      )}

      {feedbackModalVisible && (
        <div className="feedback-modal-overlay">
          <div className="feedback-modal-container">
            <button className="close-button" onClick={closeFeedbackModal}>X</button>
            <div className="feedback-content">
              <p>{feedbackContent}</p> {/* Muestra el comentario */}
            </div>
          </div>
        </div>
      )}

      {/* Modal para mostrar la URL */}
      {modalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>X</button>
            <iframe src={modalUrl} title="View Content" className="modal-iframe"></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default FollowUp;